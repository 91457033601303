@font-face {
	font-family: 'neuehaasdisplay-regular';
	src: url('/assets/fonts/neuehaasdisplayroman.ttf');
	font-display: swap;
}

@font-face {
	font-family: 'neuehaasdisplay-semibold';
	src: url('/assets/fonts/neuehaasdisplaymediu.ttf');
	font-display: swap;
}

@font-face {
	font-family: 'neuehaasdisplay-bold';
	src: url('/assets/fonts/neuehaasdisplaybold.ttf');
	font-display: swap;
}

/* You can add global styles to this file, and also import other style files */
body {
    font-size: 16px;
    font-family: 'neuehaasdisplay-regular';
    padding: 0;
    margin: 0;
    color: #151515;
}

.container {
    width: 1180px;
    margin: 0 auto;
}

a {
    text-decoration: none;
    color: #151515;
}

.cb::after {
    clear: both;
    display: block;
    content: '';
}

.news-grid {
    display: grid !important;
    gap: 20px;

    .news-grid-column {
        app-news {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
}

.image-holder {

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.programs {
    .program {
        border-left: 3px solid #d3e0f1;
        display: block;
        padding-left: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #e5e5e5;
        font-size: 15px;
        font-family: 'neuehaasdisplay-semibold';

        &:last-child {
            border-bottom: none;
        }

        .date {
            color: #2264b7;
            margin-bottom: 15px;
        }

        .description {
            margin-bottom: 15px;
        }

        .place {
            font-family: 'neuehaasdisplay-regular';
        }
    }
}

.news-detail-content {

    strong {
        font-weight: bold;
    }

	p.highlighted {
		padding: 25px !important;
		background-color: #E0E0E0 !important;
		font-weight: 400 !important;
		border-left: 2px solid #b2b2b2 !important;
	}

	p.raised {
		text-decoration: underline !important;
		text-decoration-color: #2264b7 !important;
		font-weight: bold !important;
	}

    p:first-child {
        margin-top: 0px;
    }
    
    p:last-child {
        margin-bottom: 0px;
    }

	h2.raised,
	h3.raised,
	h4.raised {
		text-decoration: underline !important;
		font-weight: 500 !important;
		text-decoration-color: #2264b7 !important;
		font-weight: bold !important;
	}
	
	blockquote {
		border-left: #ccc 5px solid;
		line-height: 1.6;
		font-style: italic;
		margin-left: 10px;
		display: block;
		padding-left: 15px;
	}
	
    img {
        width: 100%;
    }

    .ql-content-formatted {
        padding: 20px;
        background-color: #E0E0E0;
		
		& + .ql-content-formatted  {
			padding-top: 0px;
		}
    }

    .ql-content-underlineheading {
        text-decoration: underline;
        font-size: 24px;
        font-weight: 500;
        text-decoration-color: #2264b7;
    }

    .ql-content-titleline {
        padding: 25px;
        background-color: #E0E0E0;
        font-size: 16px;
        font-weight: 400;
        border-left: 2px solid #b2b2b2;

		& + .ql-content-titleline {
			padding-top: 0px;
			margin-top: -15px;
		}
    }

    .ql-video {
        margin: 0 auto;
        display: block;
    }

    p {
        line-height: 31px;
        margin: 16px 0px;
        font-size: 17px;

		a {
			font-weight: bold;
		}
    }

    ul {
        li {
            font-size: 17px;
            line-height: 31px;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 16px 0px;
    }

    blockquote {
        border-left: #ccc 5px solid;
        line-height: 1.6;
        font-style: italic;
        margin-left: 10px;
        display: block;
        padding-left: 15px;
    }
}

@media screen and (max-width: 1200px) {
    .container {
        width: auto;
        padding: 0px 20px;
    }

    .news-grid {
        display: grid !important;
        gap: 20px;
    
        &.two-columns {
            grid-template-columns: 1fr;
        }
    
        &.three-columns {
            grid-template-columns: 1fr;
        }
    
        .news-grid-column {
            app-news {
                margin-bottom: 20px;
    
                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    }
}

.table-content {
	text-align: left;

	table {
		display: inline-block;
		border: none;
	}

	td {
		padding: 8px;
	}
}

.table-data {
	border-collapse: collapse;

	tbody {
		tr:nth-child(2n+0) {
			background: #ededed;
		}

		tr {
			&:not(:first-child) {
				td {
					border: 1px solid #cfcfcf;
				}
			}
		}
	}
}

.popup-ad {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.719);
	z-index: 20;

	.ad {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		background: rgba(255, 255, 255, 0.5);

		.ad-close {
			position: absolute;
			right: 0;
			top: 0;
			line-height: 36px;
			padding: 0px 10px;
			cursor: pointer;
			background: rgba(255, 255, 255, 0.747);
			z-index: 2;
		}
	}
}

.outer-ad {
	overflow: hidden;
	max-width: calc(100vw - 40px);
	text-align: center;
}

@media screen and (max-width: 1200px) {

	.outer-ad {
		max-width: calc(100vw - 40px);
	}
}

.rltd_outer {
	float: none !important;
}

.rltd_tag {
	z-index: 0 !important;
}

.block-selector-name {

	.block-selector-name-inner {
		display: inline-block;
		padding-bottom: 8px;
		padding-top: 8px;
		padding: 6px 10px;
		font-size: 15px;
	}

	& + .block-selector-line {
		margin-top: 0px;
	}
}